<template>
  <div class="voucher_detail_cmps">
    <div class="cus_tally_box">
      <el-row type="flex">
        <el-col :span="8">&nbsp;</el-col>
        <el-col :span="8" align="center" class="tit">记账凭证</el-col>
        <el-col :span="8" align="right" class="right_btn">
          <el-link icon="el-icon-position" type="primary" :underline="false" @click="$emit('onBackToList')"> <i></i> 返回列表&nbsp;&nbsp;&nbsp;&nbsp;</el-link>
          <!-- <el-link icon="el-icon-printer" type="primary" :underline="false">打印&nbsp;&nbsp;&nbsp;&nbsp;</el-link> -->
          <el-link icon="el-icon-arrow-left" type="primary" :underline="false" @click="handleFindItem('prev')">上一张&nbsp;&nbsp;&nbsp;&nbsp;</el-link>
          <el-link icon="el-icon-arrow-right" type="primary" :underline="false" @click="handleFindItem('next')">下一张</el-link>
        </el-col>
      </el-row>
      <el-row type="flex" style="padding: 0 10px" align="middle">
        <el-col :span="12">{{ $moment(detailInfo.voucherDate).format('YYYY-MM-DD') }}&nbsp;&nbsp; {{ detailInfo.voucherNo ? `记-${detailInfo.voucherNo}号` : '' }}</el-col>
        <el-col v-if="detailInfo.invoiceCount > 0" :span="12" align="right">
          对应发票：<el-button style="padding: 0" :underline="false" type="text" @click="handleTogo(detailInfo)">{{ detailInfo.invoiceCount || 0 }}张</el-button></el-col
        >
      </el-row>
      <table border>
        <!-- <caption>
          记账凭证
          <span>
            <el-link>返回列表</el-link>
            <el-link>打印</el-link>
            <el-link>上一张</el-link>
            <el-link>下一张</el-link>
          </span>
        </caption> -->
        <!-- <thead>
          <tr>
            <th>摘要</th>
            <th rowspan="2">会计科目</th>
            <th>借方金额</th>
            <th>贷方金额</th>
          </tr>
        </thead> -->
        <tbody>
          <tr>
            <th rowspan="2" width="220">摘要</th>
            <th rowspan="2">会计科目</th>
            <th colspan="13">借方金额</th>
            <th colspan="13">贷方金额</th>
          </tr>
          <tr>
            <td>百</td>
            <td>十</td>
            <td>亿</td>
            <td>千</td>
            <td>百</td>
            <td>十</td>
            <td>万</td>
            <td>千</td>
            <td>百</td>
            <td>十</td>
            <td>元</td>
            <td>角</td>
            <td>分</td>

            <td>百</td>
            <td>十</td>
            <td>亿</td>
            <td>千</td>
            <td>百</td>
            <td>十</td>
            <td>万</td>
            <td>千</td>
            <td>百</td>
            <td>十</td>
            <td>元</td>
            <td>角</td>
            <td>分</td>
          </tr>
          <tr v-for="item in detailInfo['items']" :key="item.id">
            <td>{{ item.resume }}</td>
            <td class="td_account">{{ item.account }}</td>
            <td v-for="(v, i) in item['debitAmount']" :key="i + 'de'">{{ v }}</td>
            <td v-for="(v, i) in item['creditAmount']" :key="i + 'cr'">{{ v }}</td>
          </tr>
          <tr>
            <td colspan="2">合计：￥{{ handleSmallToCapitalize(detailInfo.totalAmount) }}</td>
            <td v-for="(v, i) in detailInfo['tmpTotalAmount']" :key="i + 'tmp_1'">{{ v }}</td>
            <td v-for="(v, i) in detailInfo['tmpTotalAmount']" :key="i + 'tmp_2'">{{ v }}</td>
          </tr>
        </tbody>
        <!-- <tfoot>
          <tr>
            <td>制单人：</td>
            <td>2222</td>
            <td>3333</td>
            <td>4444</td>
          </tr>
        </tfoot> -->
      </table>
      <el-row class="footer_info" type="flex" :gutter="20">
        <el-col :span="4">制单人：{{ detailInfo.creatorName }}</el-col>
        <el-col :span="4">审核人：xxx</el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { changeMethods } from '@/util/mixins';
import { getInvoiceCenterByPage } from '@/service/invoice-center';
export default {
  mixins: [changeMethods],
  data() {
    return {
      detailInfo: {},
      records: [],
      curIdx: 0,
      total: 0
    };
  },
  props: {
    curInfo: Object
  },

  watch: {
    curInfo: {
      handler({ item, idx, total, records }) {
        this.total = total;
        this.curIdx = idx;
        records.forEach((v) => {
          this._translateTableData(v);
        });
        this.records = JSON.parse(JSON.stringify(records));
        this.detailInfo = this.records[this.curIdx];
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleTogo({ id }) {
      getInvoiceCenterByPage({ voucherId: id }).then((res) => {
        const {
          data: { records = [] }
        } = res;
        let routeUrl = '';
        const isIncInvoice = records[0].isIncInvoice;
        const isVatInvoice = records[0].isVatInvoice;
        const isSalInvoice = records[0].isSalInvoice;
        console.log(isIncInvoice, isVatInvoice, isSalInvoice);

        if (!isVatInvoice) {
          routeUrl = this.$router.resolve({
            path: '/pool/income',
            query: { voucherId: id, type: 'other' }
          });
        } else {
          if (isSalInvoice) {
            routeUrl = this.$router.resolve({
              path: '/pool/sales',
              query: { voucherId: id }
            });
          } else {
            routeUrl = this.$router.resolve({
              path: '/pool/income',
              query: { voucherId: id, type: 'vat' }
            });
          }
        }

        window.open(routeUrl.href, '_blank');
      });
    },
    handleFindItem(type) {
      if (type == 'prev') {
        if (this.curIdx == 0) {
          return this.toast('已经是第一张了！', 'warning');
        }
        this.curIdx--;
      }
      if (type == 'next') {
        if (this.curIdx == this.total) {
          return this.toast('已经是最后一张了！', 'warning');
        }
        this.curIdx++;
      }
      this.detailInfo = this.records[this.curIdx];
    },

    // 单元格数据转换
    _translateTableData(cur) {
      let tmpItems = JSON.parse(JSON.stringify(cur['items']));
      tmpItems.forEach((v) => {
        if (v.debitAmount == null) {
          v.debitAmount = ['', '', '', '', '', '', '', '', '', '', '', '', ''];
        } else {
          v.debitAmount = v.debitAmount.toFixed(2).toString();
          let tmpD = v.debitAmount.split('');
          tmpD = tmpD.filter((item) => item != '.');
          let tmpEmpty = [];
          for (let j = 0; j < 13 - tmpD.length; j++) {
            tmpEmpty = ['', ...tmpEmpty];
          }
          v.debitAmount = [...tmpEmpty, ...tmpD];
        }
        if (v.creditAmount == null) {
          v.creditAmount = ['', '', '', '', '', '', '', '', '', '', '', '', ''];
        } else {
          v.creditAmount = v.creditAmount.toFixed(2).toString();
          let tmpD = v.creditAmount.split('');
          tmpD = tmpD.filter((item) => item != '.');
          let tmpEmpty = [];
          for (let j = 0; j < 13 - tmpD.length; j++) {
            tmpEmpty = ['', ...tmpEmpty];
          }
          v.creditAmount = [...tmpEmpty, ...tmpD];
        }
      });

      let tmpTotal = cur.totalAmount;
      if (tmpTotal != null) {
        tmpTotal = tmpTotal.toFixed(2).toString();
        tmpTotal = tmpTotal.split('');
        tmpTotal = tmpTotal.filter((item) => item != '.');
        let tmpEmpty = [];
        for (let j = 0; j < 13 - tmpTotal.length; j++) {
          tmpEmpty = ['', ...tmpEmpty];
        }
        tmpTotal = [...tmpEmpty, ...tmpTotal];
        cur.tmpTotalAmount = tmpTotal;
      }
      cur.items = tmpItems;
    }
  }
};
</script>

<style scoped lang="scss">
.voucher_detail_cmps {
  position: relative;
  height: calc(100vh - 156px);
  overflow: auto;
  background: #fff;
  box-sizing: border-box;
  padding: 24px;
  .cus_tally_box {
    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 20px;
      border: 1px solid #ccc;
      td {
        color: #666;
      }
      tr:nth-child(1) {
        th {
          padding: 20px;
        }
      }

      tr:nth-child(2) {
        td {
          width: 20px;
          text-align: center;
        }
        td:nth-child(2),
        td:nth-child(5),
        td:nth-child(8),
        td:nth-child(15),
        td:nth-child(18),
        td:nth-child(21) {
          border-right-color: #409eff;
        }
        td:nth-child(11),
        td:nth-child(24) {
          border-right-color: #f56c6c;
        }
      }
      tr:nth-child(n + 3) {
        td:nth-child(1) {
          padding: 20px;
        }
        td:nth-child(n + 3) {
          text-align: center;
        }
        td:nth-child(4),
        td:nth-child(7),
        td:nth-child(10),
        td:nth-child(17),
        td:nth-child(20),
        td:nth-child(23) {
          border-right-color: #409eff;
        }
        td:nth-child(13),
        td:nth-child(26) {
          border-right-color: #f56c6c;
        }
      }
      tr:last-child {
        td:nth-child(3),
        td:nth-child(6),
        td:nth-child(9),
        td:nth-child(16),
        td:nth-child(19),
        td:nth-child(22) {
          border-right-color: #409eff;
        }
        td:nth-child(12),
        td:nth-child(25) {
          border-right-color: #f56c6c;
        }
        td:nth-child(4),
        td:nth-child(7),
        td:nth-child(10),
        td:nth-child(13),
        td:nth-child(17),
        td:nth-child(20),
        td:nth-child(23),
        td:nth-child(26) {
          border-right-color: #ccc;
        }
      }
    }
    .tit {
      font-size: 24px;
      color: #333;
      margin-bottom: 20px;
    }
    .right_btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .footer_info {
      padding: 20px;
      color: #666;
    }
  }
  .td_account {
    padding: 20px;
  }
}
</style>
