<template>
  <div class="vouche_guide_cmps">
    <div class="tit">使用引导</div>
    <el-row type="flex" align="middle">
      <el-col class="card_box">
        <h5>1、完善基础档案</h5>
        <ul class="base_info_list">
            <!-- <li class="base_info_item">
              <span>同步用户</span>
              <i class="el-icon-success">&nbsp;&nbsp;已同步</i>
            </li> -->
            <li class="base_info_item">
              <span>同步往来单位</span>
              <i v-if="customDataStatus == 1" class="el-icon-time" @click="handleSysnc(1)">&nbsp;&nbsp;开始同步</i>
              <!-- <span v-if="customDataStatus == 2" class="el-icon-loading-txt"> <i class="el-icon-loading"></i>&nbsp;&nbsp;同步中</span> -->
              <i v-if="customDataStatus == 3" class="el-icon-success">&nbsp;&nbsp;已同步</i>
              <i v-if="customDataStatus == 4" class="el-icon-question">&nbsp;&nbsp;同步失败</i>
            </li>
            <li class="base_info_item">
              <span>同步项目</span>
              <i v-if="projectDataStatus == 1" class="el-icon-time" @click="handleSysnc(2)">&nbsp;&nbsp;开始同步</i>
              <!-- <span v-if="projectDataStatus == 2" class="el-icon-loading-txt"> <i class="el-icon-loading"></i>&nbsp;&nbsp;同步中</span> -->
              <i v-if="projectDataStatus == 3" class="el-icon-success">&nbsp;&nbsp;已同步</i>
              <i v-if="projectDataStatus == 4" class="el-icon-question">&nbsp;&nbsp;同步失败</i>
            </li>
            <li class="base_info_item">
              <span>同步会计科目</span>
              <i v-if="accountsDataStatus == 1" class="el-icon-time" @click="handleSysnc(3)">&nbsp;&nbsp;开始同步</i>
              <!-- <span v-if="accountsDataStatus == 2" class="el-icon-loading-txt"> <i class="el-icon-loading"></i>&nbsp;&nbsp;同步中</span> -->
              <i v-if="accountsDataStatus == 3" class="el-icon-success">&nbsp;&nbsp;已同步</i>
              <i v-if="accountsDataStatus == 4" class="el-icon-question">&nbsp;&nbsp;同步失败</i>
            </li>
      </ul>
      </el-col>
      <el-col :span="1" class="icon_right_box"><i class="el-icon-arrow-right"></i></el-col>
      <el-col class="card_box">
        <h5>2、设置凭证规则</h5>  
        <p>设置常用凭证模板。</p>
        <el-button class="btn" type="primary" size="mini" @click="$router.push('/voucher/template')">去添加</el-button>
      </el-col>
      <el-col :span="1" class="icon_right_box"><i class="el-icon-arrow-right"></i></el-col>
      <el-col class="card_box">
        <h5>3、自动生成凭证</h5>
        <p>勾选发票，点击生成凭证，选择模版后自动生成凭证。</p>
        <el-button class="btn" type="primary" size="mini" @click="$router.push('/pool/income')">去记账</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
const timeCount = 6;
import { syncCustom } from '@/service/customer.js';
import { projectSync, syncAccounts } from '@/service/voucher.js';
export default {
  components: {},
  data() {
    return {
      timerC: null,
      timerA: null,
      timerP: null,
      timeCCount: 0,
      timeACount: 0,
      timePCount: 0,
      customDataStatus: 1, //1开始同步 2同步中 3已同步 4同步失败
      projectDataStatus: 1, //1开始同步 2同步中 3已同步 4同步失败
      accountsDataStatus: 1 //1开始同步 2同步中 3已同步 4同步失败
    };
  },
  created() {
    // this._syncCustom();
    // this._projectSync();
    // this._syncAccounts();
  },
  mounted() {},
  methods: {
    handleSysnc(type) {
      switch (type) {
        case 1:
          this._syncCustom();
          break;
        case 2:
          this._projectSync();
          break;
        case 3:
          this._syncAccounts();
          break;
        default:
          break;
      }
    },
    // 往来单位
    async _syncCustom() {
      // this._stopLoopCustom();
      const { success } = await syncCustom();
      this.customDataStatus = success ? 3 : 4;
      // if (!success) return (this.customDataStatus = 4);
      // if (success) {
      //   this.customDataStatus = 3;
      //   return this.toast('往来单位同步成功', 'success');
      // }
      // 同步中
      // if (JSON.stringify(data) == '{}') {
      //   this.customDataStatus = 2;
      //   this.timeCCount++;
      //   this.timeCCount <= timeCount && this._loopCustom();
      // }
    },
    _loopCustom() {
      this._stopLoopCustom();
      this.timerC = setInterval(() => {
        this._syncCustom();
      }, 10000);
    },
    _stopLoopCustom() {
      if (this.timerC) {
        clearInterval(this.timerC);
        this.timerC = null;
      }
    },
    // 同步项目
    async _syncAccounts() {
      this._stopLoopAccounts();
      const { success } = await syncAccounts();
      this.accountsDataStatus = success ? 3 : 4;
      // if (!success) return (this.accountsDataStatus = 4);

      // if (success) {
      //   this.accountsDataStatus = 3;
      //   return this.toast('项目同步成功', 'success');
      // }
      // 同步中
      // if (JSON.stringify(data) == '{}') {
      //   this.accountsDataStatus = 2;
      //   this.timeACount++;
      //   this.timeACount <= timeCount && this._loopAccounts();
      // }
    },
    _loopAccounts() {
      this.timerA = setInterval(() => {
        this._syncAccounts();
      }, 10000);
    },
    _stopLoopAccounts() {
      if (this.timerA) {
        clearInterval(this.timerA);
        this.timerA = null;
      }
    },
    // 同步会计科目
    async _projectSync() {
      this._stopLoopProject();
      const { success } = await projectSync();
      this.projectDataStatus = success ? 3 : 4;

      // if (!success) return (this.projectDataStatus = 4);
      // if (success) {
      //   this.projectDataStatus = 3;
      //   return this.toast('会计科目同步成功', 'success');
      // }
      // // 同步中
      // if (JSON.stringify(data) == '{}') {
      //   this.projectDataStatus = 2;
      //   this.timePCount++;
      //   this.timePCount <= timeCount && this._loopProject();
      // }
    },
    _loopProject() {
      setInterval(() => {
        this._projectSync();
      }, 10000);
    },
    _stopLoopProject() {
      if (this.timerP) {
        clearInterval(this.timerP);
        this.timerP = null;
      }
    }
  },
  destroyed() {
    this._stopLoopCustom();
    this._stopLoopAccounts();
    this._stopLoopProject();
  }
};
</script>

<style scoped lang="scss">
.vouche_guide_cmps {
  position: relative;
  height: calc(100vh - 156px);
  overflow: auto;
  background: #fff;
  box-sizing: border-box;
  padding: 24px;
  .tit {
    font-size: 16px;
    color: #333333;
    margin-bottom: 20px;
  }
  .card_box {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
    padding: 20px 20px 0 20px;
    max-width: 240px;
    height: 220px;
    .btn {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
    h5 {
      margin: 0;
      font-size: 14px;
    }
    p {
      color: #666;
    }

    .base_info_list {
      list-style: none;
      padding-left: 0;

      .base_info_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        font-size: 14px;
        span {
          font-size: 14px;
          color: #666666;
        }
        .el-icon-success {
          color: #67c23a;
        }
        .el-icon-loading,
        .el-icon-loading-txt {
          color: #e6a23c;
        }
        .el-icon-time {
          color: #409eff;
          cursor: pointer;
        }
        .el-icon-question {
          color: #f56c6c;
        }
      }
    }
  }
  .icon_right_box {
    margin: 0 20px;
    text-align: center;
  }
}
.vouche_guide_cmps .card_box:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.5) !important;
  // background-color: #0d4fca !important;
}

</style>
