<template>
  <div class="voucher_list_view">
    <template v-if="!isShowGuide">
      <div class="content-select">
        <div class="search_box">
          <el-form ref="filterForm" :model="queryParams" inline label-width="80px">
            <el-row class="row-first">
              <el-form-item label="会计期间" prop="periodNo" class="mb">
                <el-date-picker v-model="queryParams.periodNo" type="month" value-format="yyyyMM" clearable placeholder="选择期间"> </el-date-picker>
              </el-form-item>
              <el-form-item label="凭证编号" prop="voucherNo" class="mb">
                <el-input v-model="queryParams.voucherNo" placeholder="请输入凭证编号" clearable @keyup.enter.native="handleQuery" />
              </el-form-item>
              <el-form-item label="凭证状态" prop="voucherStatus" class="mb">
                <el-select v-model="queryParams.voucherStatus" clearable placeholder="请选择凭证状态">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="业务类型" prop="bizType" class="mb">
                <el-select v-model="queryParams.bizType" clearable placeholder="请选择业务类型">
                  <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleQuery">查询</el-button>
                <el-button type="default" @click="resetQuery">重置</el-button>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="content-main">
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <div class="btn_box">
              <el-button type="primary" @click="handleBatch('push')" :disabled="isDisableBtn">推送</el-button>
              <el-button @click="handleBatch('update')" :disabled="isDisableBtn">更新</el-button>
              <el-button @click="handleBatch('del')" :disabled="isDisableBtn">删除</el-button>
            </div>
          </el-col>
          <el-col :span="6" :offset="6" align="right">
            <div class="btn_box" style="font-size: 14px">
              <el-link type="primary" :underline="false" @click="changeCmps('Guide')"><i class="el-icon-s-promotion"></i> 使用引导&nbsp;&nbsp;&nbsp;&nbsp;</el-link>
              <span>
                排序：<el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">{{ curCon }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="JSON.stringify(item)" v-for="(item, index) in commandList" :key="index">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </div>
          </el-col>
        </el-row>

        <div class="cus_vo_table">
          <el-row class="cus_t_header" type="flex">
            <el-col class="cus_t_header_item" :span="1"><el-checkbox v-model="isCheckedAll" @change="handleChooseAll" :disabled="!records.length" /></el-col>
            <el-col class="cus_t_header_item" :span="6">摘要</el-col>
            <el-col class="cus_t_header_item" :span="9">科目</el-col>
            <el-col class="cus_t_header_item" :span="4">借方金额</el-col>
            <el-col class="cus_t_header_item" :span="4">贷方金额</el-col>
          </el-row>

          <el-row class="cus_t_body_box" v-for="(item, index) in records" :key="item.id" style="cursor: pointer">
            <div class="sta_icon" :class="item.voucherStatus == 'YTS' ? '' : 'no'">{{ item.voucherStatus == 'YTS' ? '已推送' : '未推送' }}</div>
            <el-row class="cus_t_body" type="flex">
              <el-col class="cus_t_body_item lh_30" :span="1"><el-checkbox v-model="item.isChecked" @change="handleItemCheked" /></el-col>
              <el-col class="cus_t_body_item lh_30" :span="6">会计期间：{{ item.periodNo }}&nbsp;&nbsp;&nbsp;&nbsp;凭证编号：{{ item.voucherNo ? `记-${item.voucherNo}` : '' }}</el-col>
              <el-col class="cus_t_body_item lh_30" :span="9"
                >制单人：{{ item.creatorName }} &nbsp;&nbsp;&nbsp;&nbsp;日期：{{ $moment(item.voucherDate).format('YYYY-MM-DD') }}&nbsp;&nbsp;&nbsp;&nbsp;对应发票：<el-button v-if="item.invoiceCount > 0" type="text" @click="handleTogo(item)">{{ item.invoiceCount || 0 }}张</el-button>
                <span v-else>--</span>
              </el-col>
              <el-col class="cus_t_body_item" :span="4">&nbsp;</el-col>
              <el-col class="cus_t_body_item" :span="4">
                <el-button size="mini" type="text" @click="changeCmps('Detail', item, index)">查看凭证</el-button>
                <el-button size="mini" type="text" @click="handleItem(item, 'push')">推送</el-button>
                <el-button size="mini" type="text" @click="handleItem(item, 'update')">更新</el-button>
                <el-popover placement="top" offset="40" trigger="manual" v-model="item.popVisible">
                  <p>确定删除该凭证？</p>
                  <!--                  <div style="margin: 10px 0">
                    <el-checkbox v-model="isNeed">同步u+删除</el-checkbox>
                  </div>-->
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="item.popVisible = false">取消</el-button>
                    <el-button size="mini" type="primary" @click="handleItem(item, 'del')">确定</el-button>
                  </div>
                  <el-button size="mini" type="text" slot="reference" @click="item.popVisible = !item.popVisible">&nbsp;&nbsp;删除</el-button>
                </el-popover>
              </el-col>
            </el-row>
            <el-row class="cus_t_body_con" type="flex" v-for="v in item['items']" :key="v.id">
              <el-col class="cus_t_body_item" :span="1">&nbsp;</el-col>
              <el-col class="cus_t_body_item" :span="6">{{ v.resume }}</el-col>
              <el-col class="cus_t_body_item" :span="9">{{ v.account }}</el-col>
              <el-col class="cus_t_body_item" :span="4">{{ v.debitAmount }}</el-col>
              <el-col class="cus_t_body_item" :span="4"> {{ v.creditAmount }}</el-col>
            </el-row>
            <el-row class="cus_t_body_con" type="flex">
              <el-col class="cus_t_body_item" :span="1">&nbsp;</el-col>
              <el-col class="cus_t_body_item" :span="15">合计：{{ handleSmallToCapitalize(item.totalAmount) }}</el-col>
              <el-col class="cus_t_body_item total" :span="4">{{ item.totalDAmount }}</el-col>
              <el-col class="cus_t_body_item" :span="4"> {{ item.totalCAmount }} </el-col>
            </el-row>
            <el-row class="cus_t_body_con" type="flex" v-if="item.operateMessage">
              <el-col class="cus_t_body_item" :span="1">&nbsp;</el-col>
              <el-col class="cus_t_body_item" :span="23">原因：{{ item.operateMessage }} 编号：{{ item.operateBatchNo }}</el-col>
            </el-row>
          </el-row>
        </div>
        <div class="content-page" v-show="records.length > 0">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[15, 30, 50, 100]" :page-size="queryParams.size" layout="total, sizes, prev, pager, next" :total="total"></el-pagination>
        </div>
        <div class="table_no_data" v-if="!records.length">
          <img src="@/assets/icon/nodata@2x.png" alt="" style="width: 104px; height: 98px" />
          <p>暂无凭证列表数据！</p>
          <div>
            <el-button @click="$router.push('/pool/income')">去记账</el-button>
            <el-button type="primary" @click="changeCmps('Guide')">使用引导</el-button>
          </div>
        </div>
      </div>

      <el-dialog width="30%" append-to-body :visible.sync="visiable" title="导入">
        <div style="margin: 10px 20px">导入</div>
        <div slot="footer" class="button-footer" style="text-align: center">
          <el-button type="default" @click="visiable = false" class="button-left">取消</el-button>
          <el-button type="primary" @click="handleDialog">确认</el-button>
        </div>
      </el-dialog>

      <!-- 批量删除 -->
      <el-dialog width="30%" append-to-body :visible.sync="delVisiable" title="提示">
        <div style="margin: 10px 20px">确定要删除吗？</div>
        <!--<div style="margin: 10px 0">
          <el-checkbox v-model="isNeed">同步u+删除</el-checkbox>
        </div>-->
        <div slot="footer" class="button-footer" style="text-align: center">
          <el-button type="default" @click="delVisiable = false" class="button-left">取消</el-button>
          <el-button type="primary" @click="handleDelDialog">确认</el-button>
        </div>
      </el-dialog>
    </template>

    <component v-else :is="curCmps" :curInfo="curInfo" @onBackToList="isShowGuide = false" />
  </div>
</template>

<script>
import { voucherList, voucherPush, voucherSync, voucherDel, voucherBatchDel } from '@/service/voucher.js';
import { getInvoiceCenterByPage } from '@/service/invoice-center';
import { tableStyle } from '@/util/mixins';
import { changeMethods } from '../../../util/mixins';
import Guide from './cmps/guide.vue'; //查看引导
import Detail from './cmps/detail.vue'; //查看凭证
export default {
  name: 'logs',
  mixins: [tableStyle, changeMethods],
  components: { Guide, Detail },
  data() {
    return {
      delVisiable: false,
      isNeed: false,
      curInfo: {},
      curCmps: 'Detail',
      isShowGuide: false,
      curCon: '按凭证日期倒序',
      commandList: [
        { value: 0, label: '按凭证日期正序' },
        { value: 1, label: '按凭证日期倒序' },
        { value: 2, label: '按凭证编码正序' },
        { value: 3, label: '按凭证编码倒序' }
      ],
      // 查询参数
      queryParams: {
        page: 1,
        size: 15,
        bizType: '',
        periodNo: '',
        voucherNo: '',
        voucherStatus: '',
        ids: '',
        sort: 1
      },
      records: [],
      total: 0,
      paging: {
        total: 0,
        size: 15,
        current: 1
      },
      pageSizes: [15, 30, 50, 100],
      visiable: false,
      options: [
        {
          value: 'YTS',
          label: '已推送'
        },
        {
          value: 'WTS',
          label: '未推送'
        }
        // {
        //   value: '',
        //   label: '推送中'
        // },
        // {
        //   value: '',
        //   label: '推送失败'
        // }
      ],
      types: [
        {
          value: 'SI',
          label: '销项'
        },
        {
          value: 'II',
          label: '进项'
        }
      ],
      isCheckedAll: false
    };
  },
  computed: {
    isDisableBtn() {
      return !this.records.some((item) => item.isChecked);
    }
  },

  created() {
    this.getList();
  },
  methods: {
    // 批量删除
    handleDelDialog() {
      voucherBatchDel(this.queryParams).then((res) => {
        if (res.success) {
          this.delVisiable = false;
          this.$message({ message: '操作成功', type: 'success' });
          this.queryParams.ids = '';
          this.getList();
        }
      });
    },
    changeCmps(cmps, item = '', idx = '') {
      this.isShowGuide = true;
      this.curCmps = cmps;
      if (cmps == 'Detail') {
        this.curInfo = {
          item: JSON.parse(JSON.stringify(this.records[idx])),
          records: JSON.parse(JSON.stringify(this.records)),
          idx,
          total: this.records.length - 1
        };
      }
    },
    handleCommand(c) {
      const { value, label } = JSON.parse(c);
      this.curCon = label;
      this.queryParams.sort = value;
      this.getList();
    },
    handleTogo({ id }) {
      getInvoiceCenterByPage({ voucherId: id }).then((res) => {
        const {
          data: { records = [] }
        } = res;
        let routeUrl = '';
        const isIncInvoice = records[0].isIncInvoice;
        const isVatInvoice = records[0].isVatInvoice;
        const isSalInvoice = records[0].isSalInvoice;
        console.log(isIncInvoice, isVatInvoice, isSalInvoice);

        if (!isVatInvoice) {
          routeUrl = this.$router.resolve({
            path: '/pool/income',
            query: { voucherId: id, type: 'other' }
          });
        } else {
          if (isSalInvoice) {
            routeUrl = this.$router.resolve({
              path: '/pool/sales',
              query: { voucherId: id }
            });
          } else {
            routeUrl = this.$router.resolve({
              path: '/pool/income',
              query: { voucherId: id, type: 'vat' }
            });
          }
        }

        window.open(routeUrl.href, '_blank');
      });
    },
    // 批量
    handleBatch(type) {
      let ids = this.records.map((item) => {
        if (item.isChecked) {
          return item.id;
        }
        return '';
      });
      ids = ids.filter((id) => id != '');
      this.queryParams.ids = ids;
      switch (type) {
        case 'push':
          voucherPush(this.queryParams).then((res) => {
            if (res.code == -1) return this.toast(res.message, 'warning');
            if (res.success) {
              this.$message({ message: '操作成功', type: 'success' });
              this.queryParams.ids = '';
              this.getList();
            }
          });

          break;
        case 'update':
          voucherSync(this.queryParams).then((res) => {
            if (res.success) {
              this.$message({ message: '操作成功', type: 'success' });
              this.queryParams.ids = '';
              this.getList();
            }
          });
          break;
        case 'del':
          this.delVisiable = true;
          break;
        default:
          break;
      }
    },
    // 单个
    handleItem(item, type) {
      this.queryParams.ids = [item.id];
      switch (type) {
        case 'push':
          voucherPush(this.queryParams).then((res) => {
            if (res.code == -1) return this.toast(res.message, 'warning');
            if (res.success) {
              this.$message({ message: '操作成功', type: 'success' });
              this.queryParams.ids = '';
              this.getList();
            }
          });
          break;
        case 'update':
          voucherSync(this.queryParams).then((res) => {
            if (res.success) this.$message({ message: '操作成功', type: 'success' });
            this.queryParams.ids = '';
            this.getList();
          });
          break;
        case 'del':
          this.records.forEach((item) => (item.popVisible = false));
          voucherDel(item.id).then((res) => {
            if (res.success) {
              this.toast('操作成功');
              this.queryParams.ids = '';
              this.getList();
            }
          });
          break;
        default:
          break;
      }
    },
    // 全选
    handleChooseAll(s) {
      this.isCheckedAll = s;
      this.records = this.isCheckedAll
        ? this.records.map((item) => {
            item.isChecked = true;
            return item;
          })
        : this.records.map((item) => {
            item.isChecked = false;
            return item;
          });
    },
    // 单选
    handleItemCheked() {
      this.isCheckedAll = this.records.every((item) => item.isChecked);
    },

    /** 查询登录日志 */
    getList() {
      this.isCheckedAll = false;
      voucherList(this.queryParams).then(({ success, data }) => {
        const { total, records } = data;
        if (success) {
          records.forEach((v) => {
            v.isChecked = false;
            v.popVisible = false;
            if (v['items']) {
              const items = v['items'];
              // items.forEach((vv) => {
              //   vv.debitAmount = 120;
              //   vv.creditAmount = 110;
              // });
              v.totalDAmount = items.reduce((total, cur) => {
                return total + cur.debitAmount;
              }, 0);
              v.totalCAmount = items.reduce((total, cur) => {
                return total + cur.creditAmount;
              }, 0);
              v.totalAmount = v.totalDAmount;
            }
          });
          this.records = records;
          this.total = total;
        }
      });
    },
    /** 搜索 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置 */
    resetQuery() {
      this.queryParams = {
        page: 1,
        size: 15,
        bizType: '',
        periodNo: '',
        voucherNo: '',
        voucherStatus: '',
        sort: 1
      };
      this.curCon = '按凭证日期倒序';

      this.getList();
    },
    // 导入
    handleImport() {
      this.$message({ message: '恭喜你，这是一条成功消息', type: 'success' });
    },
    // 同步
    handleUpdate() {
      this.$message.error('错了哦，这是一条错误消息');
    },
    // 操作
    handleTableOpt(row, type) {
      console.log(row, type);
    },
    handleSizeChange(val) {
      this.queryParams.page = 1;
      this.queryParams.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.queryParams.page = val;
      this.getList();
    },
    // 确认
    handleDialog() {
      this.$confirm('哈哈哈哈', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {})
        .catch(() => {
          this.toast('取消清空', 'warning');
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.voucher_list_view {
  .table_no_data {
    text-align: center;
    height: calc(100vh - 485px);
    margin-top: 100px;
    p {
      line-height: 22px;
      font-size: 18px;
      color: #666;
    }
  }
  .search_box {
    padding: 20px 0 5px 20px;
  }
  .btn_box {
    padding-bottom: 10px;
  }
  .el-button--text {
    padding: 0 !important;
  }
  .cus_vo_table {
    .cus_t_header {
      background: #ebf4ff;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      .cus_t_header_item {
        text-align: center;
        &:nth-child(1) {
          width: 60px;
        }
      }
      .cus_t_header_item + .cus_t_header_item {
        border-left: 1px solid #fff;
      }
    }
    .cus_t_body_box {
      position: relative;
      border-bottom: 1px solid #ebf4ff;
      .sta_icon {
        position: absolute;
        width: 66px;
        height: 30px;
        line-height: 30px;
        transform: rotate(-22.23deg);
        border-radius: 4px;
        border: 1px solid #f3d19e;
        z-index: 2;
        color: #e6a23c;
        font-size: 14px;
        text-align: center;
        left: 68%;
        top: 30px;
        &.no {
          border: 1px solid #fab6b6;
          color: #fab6b6;
        }
      }
    }
    .cus_t_body_box + .cus_t_body_box {
      margin-top: 10px;
    }
    .cus_t_body,
    .cus_t_body_con {
      margin-top: 10px;
      background: #ebf4ff;
      font-size: 12px;

      .cus_t_body_item {
        &.lh_30 {
          height: 30px;
          line-height: 30px;
        }
        padding: 0 10px;
        color: #666;
        &:nth-child(1) {
          text-align: center;
          width: 60px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          text-align: left;
        }
        &:nth-child(4),
        &:nth-child(5) {
          text-align: right;
        }
        &.total {
          text-align: right;
        }
      }

      .cus_t_body_item + .cus_t_body_item {
        border-left: 1px solid #fff;
      }
    }
    .cus_t_body_con {
      background: #fff;
      margin: 0;
      .cus_t_body_item {
        // height: 40px;
        // line-height: 40px;
        padding: 10px;
      }
      .cus_t_body_item + .cus_t_body_item {
        border-left: 1px solid #eee;
      }
      &:nth-child(2n) {
        background: #fafafa;
      }
    }
  }
  .mb {
    margin-bottom: 10px;
  }
}
</style>
